(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name trek.filter:nl2br
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('trek')
    .filter('nl2br', nl2br);

  function nl2br() {
    return function (str) {
      if (!str) {
        return str
      }
      return str.replace(/\n\r?/g, '<br>');
    };
  }
}());
